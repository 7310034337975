import * as Yup from "yup";
import "yup-phone";
import cardValidator from "card-validator";
import { isValidIBAN } from "ibantools";

import { DeliveryType, DiscountType, PromoFilterSelected, PromoType, PromoRequirementSelected, OperatorServices, OperatorOrderType, TeamMeberType, OperatorOrderStatus } from "types";

const regex = {
  rid: /^([a-zA-Z]{2,})-([0-9]{1,})$/,
  vat: /^(?:[a-zA-Z]{2,3})?[0-9]{3,11}$/,
  uniqueCode: /^[a-zA-Z0-9]{7,7}$/gm,
  hex: /^#([0-9a-f]{3}|[0-9a-f]{6})$/i,
  recipientCode: /^[a-zA-Z0-9]{7,7}$/gm,
};

function checkShopDeliveryType(typeToCheck: DeliveryType, yupData?: any) {
  const shopContext = yupData?.options?.context;
  return shopContext !== undefined && shopContext.deliveryType === typeToCheck;
}

function checkShopDeliveryMethodIsLocal(yupData?: any) {
  const shopContext = yupData?.options?.context;
  return shopContext !== undefined && shopContext.deliveryMethod === "local";
}

function checkShopTableBookingTypeIsSeats(yupData?: any) {
  const shopContext = yupData?.options?.context;
  return shopContext !== undefined && shopContext.openTable.bookingType === "seats";
}

export const loginSchema = Yup.object().shape({
  email: Yup.string().email("Inserisci una email valida").required("required"),
  password: Yup.string().required("required"),
  remember: Yup.boolean(),
});

export const signupSchema = Yup.object().shape({
  name: Yup.string().required("required"),
  surname: Yup.string().required("required"),
  email: Yup.string().email("Inserisci una mail valida").required("required"),
  password: Yup.string().min(8, "Lunghezza minima di 8 caratteri").required("required"),
  passwordConfirm: Yup.string()
    .equals([Yup.ref("password")], "La password non è uguale")
    .required("required"),
  sellerRID: Yup.string().matches(regex.rid, "Codice non valido"),
});

export const brandSchemas = {
  billingSchema: {
    billing: Yup.object().shape(
      {
        companyName: Yup.string().required("required"),
        fullAddress: Yup.string().required("required"),
        vat: Yup.string().matches(regex.vat, "Partita IVA non valida").required("required"),
        uniqueCode: Yup.string().when("pec", {
          is: (val: string) => val !== undefined,
          then: Yup.string(),
          otherwise: Yup.string().matches(regex.uniqueCode, "Codice univoco non valido").required("required"),
        }),
        pec: Yup.string().when("uniqueCode", {
          is: (val: string) => val !== undefined,
          then: Yup.string().email("Inserisci una mail valida"),
          otherwise: Yup.string().email("Inserisci una mail valida").required("required"),
        }),
      },
      [["uniqueCode", "pec"]]
    ),
  },
  general: {
    name: Yup.string().required("required"),
    desc: Yup.string(),
    appDesc: Yup.string().required("required"),
    color: Yup.string().matches(regex.hex, "Colore non valido").required("required"),
  },
  card: {
    cardNumber: Yup.string()
      .test("test-number", "Numero carta non valido", (value) => cardValidator.number(value).isValid)
      .required("required"),
    cvc: Yup.string().min(3, "CVC non valido").max(4, "CVC non valido").required("required"),
    expireDate: Yup.string()
      .test("test-expire", "Scadenza non valida", (value) => cardValidator.expirationDate(value).isValid)
      .required("required"),
    holder: Yup.string().required("required"),
  },
  bankAccount: {
    holder: Yup.string().required("required"),
    email: Yup.string().email("Email non valida").required("required"),
    iban: Yup.string()
      .test("test-iban", "Iban non valido", (value) => isValidIBAN(value as string))
      .required("required"),
  },
};

export function paymentSchema(paymentMethod?: "card" | "bank") {
  return Yup.object().shape(paymentMethod === "card" ? { card: Yup.object().shape(brandSchemas.card) } : paymentMethod === "bank" ? { bankAccount: Yup.object().shape(brandSchemas.bankAccount) } : {});
}

export const brandBillingInfoSchema = Yup.object().shape({
  brand: Yup.object().shape(brandSchemas.billingSchema),
});

export const brandGeneralSchema = Yup.object().shape({
  brand: Yup.object().shape(brandSchemas.general),
});

export function brandCompleteSchema(paymentMethod?: "card" | "bank") {
  Yup.object().shape({
    brand: Yup.object().shape({ ...brandSchemas.general, ...brandSchemas.billingSchema }),
    card: paymentMethod === "card" ? Yup.object().shape({ ...brandSchemas.card }) : Yup.object(),
    bankAccount: paymentMethod === "bank" ? Yup.object().shape({ ...brandSchemas.bankAccount }) : Yup.object(),
  });
}

export const createTeamMemberSchema = Yup.object().shape({
  name: Yup.string().required("required"),
  surname: Yup.string().required("required"),
  email: Yup.string().email("Inserisci una mail valida").required("required"),
  phone: Yup.string().phone("IT", undefined, "Inserisci un numero valido").required("required"),
  shop: Yup.array().of(Yup.string()).required("required"),
  type: Yup.string().required("required"),
  password: Yup.string().min(8, "Lunghezza minima di 8 caratteri").required("required"),
  passwordConfirm: Yup.string()
    .equals([Yup.ref("password")], "La password non è uguale")
    .required("required"),
  services: Yup.array().when("type", { is: "operator", then: (schema) => schema.min(1, "Seleziona Servizi").of(Yup.string()).required("required") }),
  permissions: Yup.array(),

  orderStatus: Yup.array().test("orderStatus", "Seleziona almeno uno stato dell'ordine", function () {
    const type = this.parent.type as TeamMeberType;
    const services = this.parent.services as OperatorServices[];
    const orderStatus = this.parent.orderStatus as OperatorOrderStatus[];

    if (type === "operator" && services.includes(OperatorServices.Orders)) {
      return orderStatus.length > 0;
    }
    return true;
  }),

  orderType: Yup.array().test("orderType", "Seleziona almeno una tipologia di ordine", function () {
    const type = this.parent.type as TeamMeberType;
    const services = this.parent.services as OperatorServices[];
    const orderType = this.parent.orderType as OperatorOrderType[];

    if (type === "operator" && services.includes(OperatorServices.Orders)) {
      return orderType.length > 0;
    }
    return true;
  }),
});

export const editTeamMemberSchema = Yup.object().shape({
  name: Yup.string().required("required"),
  surname: Yup.string().required("required"),
  email: Yup.string().email("Inserisci una mail valida").required("required"),
  phone: Yup.string().phone("IT", undefined, "Inserisci un numero valido").required("required"),
  shop: Yup.array().of(Yup.string()).required("required"),
  services: Yup.array().when("type", { is: "operator", then: (schema) => schema.min(1, "required").of(Yup.string()).required("required") }),
  permissions: Yup.array(),
  orderStatus: Yup.array().test("orderStatus", "Seleziona almeno uno stato dell'ordine", function () {
    const type = this.parent.type as TeamMeberType;
    const services = this.parent.services as OperatorServices[];
    const orderStatus = this.parent.orderStatus as OperatorOrderStatus[];

    if (type === "operator" && services.includes(OperatorServices.Orders)) {
      return orderStatus.length > 0;
    }
    return true;
  }),

  orderType: Yup.array().test("orderType", "Seleziona almeno una tipologia di ordine", function () {
    const type = this.parent.type as TeamMeberType;
    const services = this.parent.services as OperatorServices[];
    const orderType = this.parent.orderType as OperatorOrderType[];

    if (type === "operator" && services.includes(OperatorServices.Orders)) {
      return orderType.length > 0;
    }
    return true;
  }),
});

export const promoEditorSchema = Yup.object().shape({
  name: Yup.string().required("required"),
  description: Yup.string().max(50),
  enabled: Yup.boolean(),
  banner: Yup.boolean().when("promoType", {
    is: (val: string) => ["code", "shipment"].indexOf(val) > -1,
    then: Yup.boolean().required("required"),
  }),
  promoType: Yup.string().oneOf(["code", "category", "item", "shipment"], "Seleziona un opzione").required("Seleziona un opzione"),
  code: Yup.string().when("promoType", {
    is: "code",
    then: Yup.string().max(12).required("required"),
  }),
  filterSelected: Yup.string().when("promoType", {
    is: (val: string) => val === "code",
    then: Yup.string().oneOf(["all", "item", "category"], "Seleziona un opzione").required("Seleziona un opzione"),
  }),
  items: Yup.array().when(["promoType", "filterSelected"], {
    is: (promoType: PromoType, filterSelected: PromoFilterSelected) => (promoType === "code" && filterSelected === "item") || promoType === "item",
    then: Yup.array().of(Yup.string()).min(1, "Inserisci almeno un prodotto"),
  }),
  categories: Yup.array().when(["promoType", "filterSelected"], {
    is: (promoType: PromoType, filterSelected: PromoFilterSelected) => (promoType === "code" && filterSelected === "category") || promoType === "category",
    then: Yup.array().of(Yup.string()).min(1, "Inserisci almeno una categoria"),
  }),
  discountType: Yup.string().when(["promoType", "filterSelected"], {
    is: (promoType: PromoType, filterSelected: PromoFilterSelected) => promoType === "code" && filterSelected === "all",
    then: Yup.string().oneOf(["percentage", "flat", "shipment"], "Seleziona un opzione").required("Seleziona un opzione"),
  }),
  minimumAmount: Yup.number().when(["promoType", "requirementSelected"], {
    is: (promoType: string, requirementSelected: PromoRequirementSelected) => ["code", "shipment"].indexOf(promoType) > -1 && requirementSelected === "minimumAmount",
    then: Yup.number().required("required"),
  }),
  minimumItems: Yup.number().when(["promoType", "requirementSelected"], {
    is: (promoType: string, requirementSelected: PromoRequirementSelected) => ["code", "shipment"].indexOf(promoType) > -1 && requirementSelected === "minimumItems",
    then: Yup.number().required("required"),
  }),
  shops: Yup.array().of(Yup.string()).min(1, "Inserisci almeno un negozio"),
  requirementSelected: Yup.string().when("promoType", {
    is: (val: string) => ["code", "shipment"].indexOf(val) > -1,
    then: Yup.string().oneOf(["none", "minimumAmount", "minimumItems"], "Seleziona un opzione").required("Seleziona un opzione"),
  }),
  amount: Yup.number().when(["promoType", "discountType"], {
    is: (promoType: PromoType, discountType: DiscountType) => promoType !== "shipment" && discountType !== "shipment",
    then: Yup.number().required("required").min(0, "Il minimo è 0"),
  }),
});

export const newNotificationSchema = Yup.object().shape({
  title: Yup.string().required("required"),
  body: Yup.string().required("required"),
});

export const enableTableSchema = Yup.object().shape({
  rid: Yup.string().matches(regex.rid, "Codice non valido").required("required"),
  shop: Yup.string().required("required").nullable(),
  table: Yup.string().required("required").nullable(),
  people: Yup.number().required("required"),
});

export const riderSchema = Yup.object().shape({
  name: Yup.string().required("required"),
  surname: Yup.string().required("required"),
  phone: Yup.string().phone("IT", undefined, "Inserisci un numero valido").required("required"),
  email: Yup.string().email("Inserisci una email valida").required("required"),
  vehicle: Yup.string().required("required"),
});

export const tableSchema = Yup.object().shape({
  name: Yup.string().required("required"),
  seats: Yup.object().shape({
    min: Yup.number().required("required").min(1, "Il tavolo deve avere almeno un posto"),
    max: Yup.number().required("required").max(100, "Il tavolo può avere al massimo 100 posti"),
  }),
});

export const saloonSchema = Yup.object().shape({
  name: Yup.string().required("required"),
  indoor: Yup.boolean().required("required"),
});

export const openTableSchema = Yup.object().shape({
  people: Yup.number().required("required"),
});

export const fiscaleMailSchema = Yup.object().shape({
  email: Yup.string().email("Inserisci una email valida").required("required"),
});

export const shopSchema = Yup.object().shape({
  name: Yup.string().required("required"),
  email: Yup.string().email("Inserisci una email valida").required("required"),
  phone: Yup.string().phone("IT", undefined, "Inserisci un numero valido").required("required"),
  address: Yup.string().required("required"),
  minPrice: Yup.number().min(0, `Il minimo d'ordine è di 0`).required("required"),
  currency: Yup.string().required("required"),
  applicationConf: Yup.object().shape({
    deliveryConf: Yup.object().shape({
      availablePayment: Yup.array()
        .of(Yup.string().oneOf(["onDelivery", "stripe", "paypal"]))
        .min(1, "Seleziona almeno un metodo di pagamento"),
      availableTime: Yup.array()
        .of(Yup.string().oneOf(["scheduled", "asap"]))
        .min(1, "Seleziona almeno un orario di consegna"),
      availableDays: Yup.array()
        .of(Yup.string().oneOf(["today", "tomorrow", "calendar"]))
        .min(1, "Seleziona almeno un giorno"),
    }),
    takeAwayConf: Yup.object().shape({
      availablePayment: Yup.array().of(Yup.string()).min(1, "Seleziona almeno un metodo di pagamento"),
      availableTime: Yup.array().of(Yup.string()).min(1, "Seleziona almeno un orario per il take away"),
      availableDays: Yup.array()
        .of(Yup.string().oneOf(["today", "tomorrow", "calendar"]))
        .min(1, "Seleziona almeno un giorno"),
    }),
    availableTablePayment: Yup.array()
      .of(Yup.string().oneOf(["onDelivery", "stripe", "paypal"]))
      .min(1, "Seleziona almeno un metodo di pagamento"),
  }),
  open: Yup.object().shape({
    week: Yup.array().of(
      Yup.object().shape({
        hours: Yup.array().of(
          Yup.object().shape({
            from: Yup.string().required("required"),
            to: Yup.string().required("required"),
            interval: Yup.number().required("required"),
            maxOrders: Yup.number().required("required"),
          })
        ),
      })
    ),
  }),
  openTable: Yup.object().shape({
    week: Yup.array().of(
      Yup.object().shape({
        hours: Yup.array().of(
          Yup.object().shape({
            from: Yup.string().required("required"),
            to: Yup.string().required("required"),
            freeTime: Yup.number().nullable(),
            discount: Yup.number().nullable(),
            maxSeats: Yup.number()
              .nullable()
              .test("maxSeats-test", "required", (value, data) => (checkShopTableBookingTypeIsSeats(data) ? value !== undefined : true)),
          })
        ),
      })
    ),
  }),
  undoTime: Yup.number().required("required"),
  deliveryType: Yup.string(),
  deliveryMethod: Yup.string(),
  capsDeliveryPrice: Yup.array().when("deliveryType", {
    is: "caps",
    then: Yup.array()
      .of(
        Yup.object().shape({
          cap: Yup.string().required("required"),
          price: Yup.number().min(0, "Il prezzo minimo è di 0").required("required"),
          minPrice: Yup.number().min(0, `L'ordine minimo è di 0`).required("required"),
          riderTime: Yup.number()
            .test("riderTime", "required", (value, data) => (!checkShopDeliveryMethodIsLocal(data) ? value !== undefined : true))
            .test("riderTime", "Il tempo minimo è di 0", (value, data) => (!checkShopDeliveryMethodIsLocal(data) ? value !== undefined : true)),
        })
      )
      .min(1, "Inserisci almeno un cap"),
  }),
  rangeDeliveryPrice: Yup.object().shape({
    availableRanges: Yup.array()
      .test("availableRanges", "Inserisci almeno un raggio", (value, data) => (checkShopDeliveryType("multipleRange", data) ? value !== undefined && value.length > 0 : true))
      .of(
        Yup.object().shape({
          range: Yup.string()
            .test("range", "required", (value, data) => (checkShopDeliveryType("multipleRange", data) && !checkShopDeliveryMethodIsLocal(data) ? value !== undefined : true))
            .test("range", "Il raggio minimo è di 0", (value, data) => (checkShopDeliveryType("multipleRange", data) && !checkShopDeliveryMethodIsLocal(data) ? value !== undefined : true)),
          price: Yup.number()
            .test("price", "required", (value, data) => (checkShopDeliveryType("multipleRange", data) && !checkShopDeliveryMethodIsLocal(data) ? value !== undefined : true))
            .test("price", "Il prezzo minimo è di 0", (value, data) => (checkShopDeliveryType("multipleRange", data) && !checkShopDeliveryMethodIsLocal(data) ? value !== undefined : true)),
          minPrice: Yup.number()
            .test("minPrice", "required", (value, data) => (checkShopDeliveryType("multipleRange", data) && !checkShopDeliveryMethodIsLocal(data) ? value !== undefined : true))
            .test("minPrice", `L'ordine minimo è di 0`, (value, data) => (checkShopDeliveryType("multipleRange", data) && !checkShopDeliveryMethodIsLocal(data) ? value !== undefined : true)),
          riderTime: Yup.number()
            .test("riderTime", "required", (value, data) => (checkShopDeliveryType("multipleRange", data) && !checkShopDeliveryMethodIsLocal(data) ? value !== undefined : true))
            .test("riderTime", "Il tempo minimo è di 0", (value, data) => (checkShopDeliveryType("multipleRange", data) && !checkShopDeliveryMethodIsLocal(data) ? value !== undefined : true)),
        })
      ),
  }),
  areaDeliveryPrice: Yup.array().when("deliveryType", {
    is: "area",
    then: Yup.array().of(
      Yup.object().shape({
        price: Yup.number().min(0, "Il prezzo minimo è di 0").required("required"),
        minPrice: Yup.number().min(0, `L'ordine minimo è di 0`).required("required"),
        riderTime: Yup.number()
          .test("riderTime", "required", (value, data) => (checkShopDeliveryType("area", data) && !checkShopDeliveryMethodIsLocal(data) ? value !== undefined : true))
          .test("riderTime", "Il tempo minimo è di 0", (value, data) => (checkShopDeliveryType("area", data) && !checkShopDeliveryMethodIsLocal(data) ? value !== undefined : true)),
      })
    ),
  }),
  tables: Yup.object().shape({
    enabled: Yup.boolean().required("required"),
    minCancelTime: Yup.number().required("required"),
    aggregation: Yup.object().shape({
      enabled: Yup.boolean(),
      time: Yup.number().when("enabled", {
        is: true,
        then: Yup.number().min(5, `Il timer minimo è di 5`).required("required"),
      }),
    }),
    deliveryMode: Yup.string().required("required"),
    paymentTime: Yup.string().required("required"),
    smsNotification: Yup.boolean().required("required"),
    minPrice: Yup.number().min(0, "Prezzo minimo 0").required("required"),
    serviceCost: Yup.object().shape({
      enabled: Yup.boolean().required("required"),
      price: Yup.number().min(0, "Il prezzo minimo è di 0").required("required"),
      options: Yup.array().of(
        Yup.object().shape({
          name: Yup.string().required("required"),
          price: Yup.number().min(0, "Il prezzo minimo è di 0").required("required"),
        })
      ),
    }),
  }),
  express: Yup.object().shape({
    enabled: Yup.boolean().required("required"),
    deliveryMode: Yup.string().required("required"),
    enablePickupMode: Yup.boolean().required("required"),
    minPrice: Yup.number().min(0, "Prezzo minimo 0").required("required"),
    orderTimer: Yup.object().shape({
      enabled: Yup.boolean(),
      timer: Yup.number().when("enabled", {
        is: true,
        then: Yup.number().min(1, `Il timer minimo è di 1`).required("required"),
      }),
    }),
  }),
  cashDesk: Yup.object().shape({
    pickupModeValues: Yup.array().of(
      Yup.object().shape({
        value: Yup.string().required("required"),
        handleOrder: Yup.boolean(),
      })
    ),
  }),
  buttons: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required("required"),
      tax: Yup.string().oneOf(["4", "10", "22", "N4"]).required("required"),
    })
  ),
});

export const printerSchema = Yup.object().shape({
  name: Yup.string().required("required"),
  isFiscal: Yup.boolean(),
  ip: Yup.string().required("required"),
  password: Yup.string(),
  port: Yup.string().required("required"),
  categories: Yup.array(Yup.string()),
  productsTextDimension: Yup.number().min(1, "La dimensione minima è di 1").required("required"),
  devices: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required("required"),
      uuid: Yup.string().required("required"),
      ordersTypes: Yup.array().of(Yup.string().oneOf(["delivery", "takeAway", "express", "tables"])),
      orderStatus: Yup.array().of(Yup.string().oneOf(["pending", "confirmed", "ready"])),
      hideDetails: Yup.boolean(),
    })
  ),
});

export const categorySchema = Yup.object().shape({
  name: Yup.string().required("required"),
  open: Yup.object().shape({
    enabled: Yup.boolean(),
    from: Yup.string().when("open.enabled", {
      is: false,
      then: Yup.string().required("required"),
    }),
    to: Yup.string().when("open.enabled", {
      is: false,
      then: Yup.string().required("required"),
    }),
  }),
  categoryType: Yup.array().of(Yup.string()).min(1, "required"),
  groups: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required("required"),
      items: Yup.array().of(Yup.string()),
    })
  ),
});

export const componentCategorySchema = Yup.object().shape({
  name: Yup.string().required("required"),
});

export const visibilitySchema = Yup.object().shape({
  brandId: Yup.string().required("required"),
  mode: Yup.string().required("required"),
});

export const itemSchema = Yup.object().shape({
  name: Yup.string().required("required"),
  description: Yup.string(),
  price: Yup.number().min(0, "Il valore deve essere superiore o uguale a 0.").required("required"),
  itemType: Yup.string(),
  availableConfigurations: Yup.array().when("itemType", {
    is: "custom",
    then: Yup.array()
      .of(
        Yup.object().shape({
          name: Yup.string().required("required"),
          possibleValuesAndPrice: Yup.array().of(
            Yup.object().shape({
              value: Yup.string().required("required"),
              price: Yup.number().min(0, "Il valore deve essere superiore o uguale a 0.").required("required"),
              limit: Yup.number().required("required"),
            })
          ),
          limit: Yup.number().min(1, "Il valore deve essere superiore o uguale a 1.").required("required"),
        })
      )
      .min(1, "Inserire almeno un opzione"),
  }),
  selectableItems: Yup.object().when("itemType", {
    is: (val: string) => val.indexOf("modular") > -1,
    then: Yup.object().shape({
      items: Yup.array()
        .of(
          Yup.object().shape({
            limit: Yup.number().required("required"),
          })
        )
        .min(1, "Selezionare almeno un prodotto"),
      limit: Yup.number().min(1, "Il valore minimo è di 1").required("required"),
      minPrice: Yup.number().when("itemType", {
        is: "modular-flex",
        then: Yup.number().required("required"),
      }),
    }),
  }),
  shops: Yup.array().of(
    Yup.object().shape({
      quantity: Yup.number().required("required"),
      price: Yup.number().nullable(),
    })
  ),
  filterType: Yup.array().of(Yup.string()),
  delay: Yup.object().shape({
    value: Yup.number().test("value", "Il valore deve essere maggiore o uguale a 3 ore", (value, data) => (value !== undefined && data.parent.unit === "hours" && value !== 0 ? value > 2 : true)),
    unit: Yup.string(),
  }),
});

export const componentSchema = Yup.object().shape({
  name: Yup.string().required("required"),
  description: Yup.string(),
  externalRef: Yup.string(),
  price: Yup.number().required("required"),
  unit: Yup.string().required("required"),
  componentType: Yup.string(),
});

export const requestResetPasswordSchema = Yup.object().shape({
  email: Yup.string().email("Inserisci una email valida").required("required"),
});

export const resetPasswordSchema = Yup.object().shape({
  password: Yup.string().min(8, "Lunghezza minima di 8 caratteri").required("required"),
  password_confirm: Yup.string()
    .equals([Yup.ref("password")], "La password non è uguale")
    .required("required"),
});

export const manualBookingSchema = Yup.object().shape({
  shop: Yup.string().required("required").nullable(),
  shopBookingType: Yup.string().nullable(),
  table: Yup.string()
    .nullable()
    .when("shopBookingType", {
      is: "table",
      then: Yup.string().required("required").nullable(),
    }),
  date: Yup.date().required("required").nullable(),
  hour: Yup.string().required("required").nullable(),
  people: Yup.number().required("required"),
  name: Yup.string().required("required"),
  surname: Yup.string().required("required"),
  email: Yup.string().email("Inserisci una email valida"),
  phone: Yup.string(),
});

export const bannerEditorSchema = Yup.object().shape({
  title: Yup.string().required("required"),
  description: Yup.string(),
  shops: Yup.array().of(Yup.string()).min(1, "Seleziona almeno un negozio"),
  enabled: Yup.boolean(),
  bannerType: Yup.string(),
  item: Yup.string().when("bannerType", {
    is: "item",
    then: Yup.string().required("Seleziona un prodotto"),
  }),
  category: Yup.string().when("bannerType", {
    is: "category",
    then: Yup.string().required("Seleziona una categoria"),
  }),
  url: Yup.string().when("bannerType", {
    is: "url",
    then: Yup.string().required("Inserisci un link esterno"),
  }),
});

export const newStripeAccountSchema = Yup.object().shape({
  email: Yup.string().email("Inserisci una email valida").required("required"),
  businessProfile: Yup.object().shape({
    productDescription: Yup.string().required("required"),
    supportEmail: Yup.string().required("required"),
    supportPhone: Yup.string().required("required"),
    url: Yup.string().required("required"),
    categoryCode: Yup.string().required("required"),
  }),
});

export const addStripeToBrandOrShopSchema = Yup.object().shape({
  type: Yup.string().oneOf(["brand", "shop"]).required("required"),
  shopId: Yup.string()
    .nullable()
    .when("type", {
      is: "shop",
      then: Yup.string().required("required").nullable(),
    }),
});

export const paypalIntegrationSchema = Yup.object().shape({
  clientId: Yup.string().required("required"),
  clientSecret: Yup.string().required("required"),
  type: Yup.string().oneOf(["brand", "shop"]).required("required"),
  shopId: Yup.string()
    .nullable()
    .when("type", {
      is: "shop",
      then: Yup.string().required("required").nullable(),
    }),
});

export const spiaggeSchema = Yup.object().shape({
  spiaggeId: Yup.string().required("required"),
  shopId: Yup.string().required("required"),
});

export const supplierSchema = Yup.object().shape({
  type: Yup.string().required("Il tipo di fornitore è obbligatorio"),
  name: Yup.string().required("required"),
  piva: Yup.object().when("type", {
    is: (val: string) => val === "piva",
    then: Yup.object({
      idIva: Yup.object({
        partitaIva: Yup.string().required("required"),
      }),
      uniqueCode: Yup.string().when("piva.pec", {
        is: "",
        then: Yup.string().required(),
      }),
      pec: Yup.string().when("piva.uniqueCode", {
        is: "",
        then: Yup.string().required(),
      }),
      indirizzo: Yup.object().shape({
        indirizzo: Yup.string().required("required"),
        comune: Yup.string().required("required"),
        cap: Yup.string().required("required"),
        provincia: Yup.string().required("required"),
      }),
    }),
  }),
});

export const nuvolaSchema = Yup.object().shape({
  code: Yup.string().required("required"),
});

export const googleAnalyticsTrackingSchema = Yup.object().shape({
  trackings: Yup.array().of(Yup.string().required("required")),
});

export const facebookPixelTrackingSchema = Yup.object().shape({
  trackings: Yup.array().of(Yup.string().required("required")),
});

export const accountSchema = Yup.object().shape({
  fuso: Yup.string().required("required"),
  language: Yup.string().required("required"),
});

export const glovoSchema = Yup.object().shape({
  brandId: Yup.string().required("required"),
  pub: Yup.string().required("required"),
  priv: Yup.string().required("required"),
});

export const experienceSchema = Yup.object().shape({
  name: Yup.string().required("required"),
  siaeCode: Yup.string().required("required"),
  from: Yup.string().required("required"),
  to: Yup.string().required("required"),
  shops: Yup.array().of(Yup.string()).min(1, "required"),
});

export const ticketSchema = Yup.object().shape({
  name: Yup.string().required("required"),
  description: Yup.string(),
});

export const structureSchema = Yup.object().shape({
  name: Yup.string().required("required"),
  description: Yup.string(),
});

export const accessorySchema = Yup.object().shape({
  tag: Yup.string().required("required"),
  amountToCreate: Yup.number().required("required"),
});

export const vatNumberSearchSchema = Yup.object().shape({
  vatNumberSearch: Yup.string().matches(regex.vat, "invalid").required("required"),
});

export const billingVatSchema = Yup.object().shape(
  {
    companyName: Yup.string().required("required"),
    vat: Yup.string().matches(regex.vat, "invalid").required("required"),
    zipCode: Yup.string().required("required"),
    country: Yup.string().required("required"),
    province: Yup.string().required("required"),
    city: Yup.string().required("required"),
    address: Yup.string().required("required"),
    pec: Yup.string().when("recipientCode", {
      is: (val: string) => val !== undefined,
      then: Yup.string().email("invalid"),
      otherwise: Yup.string().email("invalid").required("required"),
    }),
    recipientCode: Yup.string().when("pec", {
      is: (val: string) => val !== undefined,
      then: Yup.string(),
      otherwise: Yup.string().matches(regex.recipientCode, "invalid").required("required"),
    }),
  },
  [["recipientCode", "pec"]]
);

export const productModalSchema = Yup.object().shape({
  discountAmount: Yup.number()
    .test("price", "Sconto superiore al prezzo", (value, data) => {
      if (data.parent.discountType === "fixed" && value != null) {
        return value <= data.parent.price;
      }

      if (data.parent.discountType === "percentage" && value != null) {
        return value <= 100;
      }
      return true;
    }),
    //TODO(Paolo): capire perchè max discount = 1000
    // .max(1000, "Valore massimo raggiunto"),
  discountType: Yup.string().when("discountAmount", {
    is: (val: number) => val && val > 0,
    then: Yup.string().required("required"),
  }),
});

export const discountModalSchema = Yup.object().shape({
  discountType: Yup.string().required("required"),
});

export const importPlatformsSchema = Yup.object().shape({
  selectedPlatform: Yup.string().required("required"),
  link: Yup.string()
    .required("required")
    .matches(/^(http|https):\/\/(www\.)?glovoapp.com(.*)$/, "Link non valido"),
});

export const MovementSchema = Yup.object().shape({
  loadType: Yup.string().required("required"),
  component: Yup.string().required("required"),
  barcode: Yup.string().required("required"),
  quantity: Yup.number().min(1).required("required"),
  orderNumber: Yup.number().required("required"),
  documentNumber: Yup.string().required("required"),
  documentType: Yup.string().required("required"),
  ivaPrice: Yup.number().required("required"),
  iva: Yup.string().required("required"),
  shop: Yup.string().required("required"),
  unitPrice: Yup.number().required("required"),
});
